
.pushbutton.power
{
    color: white;
/*  text-align: center; */
    vertical-align: -50%;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: url("../../public/OIP_24x24.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    width:  40px;
    height: 40px;
    background-color: white;
}

.pushbutton.reset
{
    color: white;
/*  text-align: center; */
    vertical-align: -50%;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: url("../../public/RIP_28x28.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    width:  40px;
    height: 40px;
    background-color: white;
}

/* .pushbutton.power { background: url("../../public/OIP_24x24.jpeg"); }
.pushbutton.reset { background: url("../../public/RIP_24x24.jpeg"); } */

.pushbutton:active
{
    /* Scaling button to 0.98 to its original size */
    transform: scale(0.98);
    /* Lowering the shadow */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

/*.pushbutton.pbdisabled:active { box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); } */
.pushbutton.pbdisabled:active { box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); }
.pushbutton.pbdisabled { cursor: default; background: #c8c8c8; }

/* .pushbutton.pushed { } */

.pushbutton.spinning
{
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    will-change: transform;
}

@keyframes spinner
{     0% { transform: rotate(0deg);   }
    100% { transform: rotate(360deg); }
}

/*
    transform: translate3d(-50%, -50%, 0);
@keyframes spinner
{     0% { transform: translate3d(-50%, -50%, 0) rotate(0deg);   }
    100% { transform: translate3d(-50%, -50%, 0) rotate(360deg); }
}
*/

.spin::before
{
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #1c87c9;
    border-radius: 50%;
    content: "";
    height: 40px;
    width: 40px;
    position: absolute;
    top: 10%;
    left: 10%;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
}


