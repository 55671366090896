
/*
 *  I know is late but others might be searching also for this, as I found no
 *  answers this worked pretty well; actually is simple, wrap always your React
 *  Table on a div and a className, then on CSS file do this:
 */

.jTableGrid {
    margin: 10px;
    border: 2px solid black;
    border-radius: 40px;
}

table, th, td {
    border-top: 1px solid black;
    padding: 10px;
}

tr.disabled {
    pointer-events: none;
}

input[type=checkbox]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /*
     * create custom checkbox appearance

    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;

     */

    /*
     * background-color only for content

     */

    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    border-radius: 6px;
    background-color: #e7e6e7;
    margin-left: 15px;
    margin-right: 15px;

    &:checked{ background-color: "gray"; }

    &:focus{ outline: none !important; }
}


/*
 * checkbox styling code example:

input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // create custom checkbox appearance
    display: inline-block;
    width: 25px;
    height: 25px;
    padding: 6px;

    // background-color only for content
    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    border-radius: 6px;
    background-color: #e7e6e7;
    margin-left: 15px;
    margin-right: 15px;

    &:checked{
        background-color: #ff0000;
    }

    &:focus{
        outline: none !important;
    }
}

    =============================
    different example:

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + span {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 12px;
  height: 12px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
}
input[type="checkbox"]:checked + span {
  background: #D9534F -19px top no-repeat;
}

input[type="checkbox"] + span {
  margin-right: 4px;
}

 */


/*
 *  The key is to have a fixed height for the div that is wrapping the table and
 *  overflow set to scroll. Also, you can keep the table header fixed by doing this:

.tableContainer{
    background-color: #1E1F25;
    border-radius: 20px;
    height: 244px;
    border-collapse: collapse;
    padding: 0 10px 10px 10px;
    overflow: scroll;
}

Table thead{ position: sticky; top: 0px; margin: 0 0 0 0; }
 */
