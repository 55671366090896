.pwrled
{
/*  position: relative; z-index: 2; */
/*  text-align: bottom;
    align-items: center; */
    display: inline-block;
    vertical-align: -30%;
    width:  24px;
    height: 24px;
    background: #444;
    border-radius: 24%;
}

.blueon
{
    margin: 1px;
    background-color: #24E0FF;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3F8CFF 0 2px 14px;
}

.iconon
{
/*  background: #fff; */
    background-color: #ffffdd;
    box-shadow: 0 0  5px #fff,
                0 0 10px #fff,
                0 0 15px #fff;

    /* add an on icon */
    background: url("../../public/sun_24x24.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    width:  24px;
    height: 24px;
    /* background-color: white; */
}

/* body.on .pwrled::after */
.ledoff
{
    margin: 1px;
    background-color: #c8c8c8;
    border-radius: 50%;
}

