
/*
import bgimage from './mainbg2c.jpg';
        <div style={{ backgroundImage: `url(${bgimage})`,
            backgroundRepeat: 'no-repeat', width: '100vw', height: '100vh' }}>
*/

.jbackground_image {
    background-image: url('../../public/mainbg2c.jpg');
    background-size: 100%;
    /* background-size: cover; */
    /* background-size: 100% 100%; */
    /* background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100%;
    height: 100vh;
*/
    position: relative;
}

.jrow {
    margin: 0 auto;     /* centers the card */
    display: grid;      /* this defines the layout approach */
    /* margin-top: 20px; */
    margin-left: 10px;
    max-width: 1500px;  /* defines the max-width of the row */
    /* max-width: 100%; */

    /* grid-template.. below creates 3 cols of equal width that squish down with size
        1fr = one fraction of the available space
    */
    /* grid-template-columns: repeat(3, 1fr); */

    /* grid-template.. below creates <n> cols of equal width that do not squish down with size.
        They have a minimum size of 225px and max of 1fr
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        1fr - fraction of screen - causes the cards to resize with screen width; I would
                prefer for the cards to be fixed in size
    */
    grid-template-columns: repeat(auto-fill, minmax(450px, 450px));
    gap: 20px;
}

.jgrid {
    padding: 5px;
    display: flex;
    border-radius: 5px;
}

.jgridrow {
    padding: 0px;
    margin: 10px;
    display: flex;
    align-items: center;
    align-text: center;
}

.jgridbottomrow {
    padding: 0px;
    margin: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    align-text: center;
}

.jgridtblrow {
    padding: 0px;
    margin: 10px;
    display: flex;
    align-items: left;
    align-text: left;
}

.jcontrols { background: #f0f0f0; border-radius: 5px; }
.jcontrols.offline { background: #cecece; border-radius: 5px; color: gray; }

.jbtnleftrow {
    margin-top:    5px;
    align-items: start;
    justify-content: space-between;
}

.jbtnrow {
/*
    border-bottom: 2px solid #333333;
*/
    margin-top:    5px;
    margin-left:  20px;
    align-items: start;
    justify-content: space-between;
    width: 430px;
}

.jbtnsmallrow {
/*
    margin-top:  10px;
    margin-top:    5px;
    margin-left:   0px;
*/
    border-top: 2px solid #333333;
    align-items: start;
    justify-content: space-between;
}

.dontuse {
    align-items: center;
    justify-content: space-between;
}

.jcard {
/*
    margin-top:  10px;
    border-radius: 5px;
    margin:  10px;
*/
    border-radius: 5px;
    margin:  5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #888888;
}

.jcard__image {
    width: 10%;    /* This causes the image to take up the width of its container (the column) */
    display: block;
}

.jcard_content {
    line-height: 1.5;
    font-size: 1em;
    padding: 5px;
    background: #fafafa;
    margin: 0px;
/*
    border-radius: 5px 5px 0px 0px;
*/
}

.jcard_content_top {
    line-height: 1.5;
    font-size: 1em;
    padding: 5px;
    background: #fafafa;
    margin: 0px;
    border-radius: 5px 5px 0px 0px;
}

.jcard_content.offline { color: gray; background: #cecece; }

/* Below code selects first <p> tag in the card
.jcard_content > div:first-of-type {
*/
.jcard_content div:first-of-type {
    margin-top: 0;  /* Make the first block of text to have 0 top margin */
    border-radius: 5px 5px 5px 5px;
}

/* Below code selects last <p> tag in the card
.jcard_content > div:last-of-type {
*/
.jcard_content div:last-of-type {
    margin-bottom: 0;  /* Make the last block of text to have 0 bottom margin */
    border-radius: 5px 5px 5px 5px;
}

.jcard_header {
    padding: 15px;  /* Same as padding for jcard_content */
    display: flex;  /* Don't understand this */
    justify-content: space-between; /* Adds space between each div so first goes on left and second on right */
    align-items: center;
    /* color: #555555; */
    color: black;
    font-weight: 500;
    background: #eeeeee;
    font-size: 1.5em;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #333333;
    /* background-image: url('../../public/pcbimage_55x58.jpeg');
    background-repeat: no-repeat; */
}

.jcard_header.offline { color: gray; background: #dfdfdf; font-weight: 100; }

/* Adjust the jcard_header style for "i" tags */
.jcard_header i {
    font-size: 0.9em;
    margin-right: 8px;
}

.jcard_section {
    padding: 5px;
    display: flex;
    align-items: center;
    color: black;
    background: #eeeeee;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 0 0 5px 5px;

    /*
    justify-content: space-between;
    font-size: 1.5em;
    font-weight: 500;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #333333;
    */
    border-top: 1px solid #333333;
}

.jcard_header2 {
    padding: 10px;  /* Same as padding for jcard_content */
    display: flex;  /* Don't understand this */
    justify-content: space-between; /* Adds space between each div so first goes on left and second on right */
    align-items: center;
    /* color: #555555; */
    color: black;
    font-weight: 500;
    background: #eeeeee;
    font-size: 1em;
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid #333333;
    /* background-image: url('../../public/pcbimage_55x58.jpeg');
    background-repeat: no-repeat; */
}

.jcard_header2.offline { color: gray; background: #dfdfdf; font-weight: 100; }

/* Adjust the jcard_header style for "i" tags */
.jcard_header2 i {
    font-size: 0.9em;
    margin-right: 8px;
}


.jcard_section.offline { color: gray; background: #dfdfdf; }

.jcard_link {
    color: #64968c;
    text-decoration: none;
}

.jcard_link:hover {
    text-decoration: underline;
}

