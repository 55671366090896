.jcounter
{
/*  position: relative; z-index: 2; */
/*  text-align: bottom;
    align-items: center; */
    display: inline-block;
/*    vertical-align: -30%; */
    width:  44px;
    height: 24px;
    border-radius: 4px;
    background: #444;
    background-color: white;
    text-align: right;
    padding: 0px 4px 0px 0px;
    margin-right: 0px;
}

/* .blueon
{
    margin: 0 auto;
    background-color: #24E0FF;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3F8CFF 0 2px 14px;
} */

/* body.on .jcounter::after */
.counteroff
{
    background-color: #c8c8c8;
    /*border-radius: 50%; */
}

