
.jecho
{
    text-align: left;
    display: inline-block;
    margin: 10px;
    font-size: 16px;
    border: 4px solid lightblue;
    border-radius: 8px;
    padding: 0px;
/*
    width:  40px;
    height: 40px;
    margin: 10px 10px;
*/
    background-color: white;
}


