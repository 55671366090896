
.pmbutton
{
/*  text-align: center;

    color: white;
    background-color: white;

    font-size: 16px;
    border: 4px;
    text-decoration: none;

    background-repeat: no-repeat;
    background: url("../../public/minus_24x25.jpg");
    border-radius: 0px;
    vertical-align: -60%;
    margin: 1px;

*/

    display: inline-block;

    cursor: arrow;
    background-position: center;
    width:  20px;
    height: 20px;
    margin: 1px;
    border: 0px;
}

.pmbutton.plus {
    /* background: url("../../public/plus_24x25.jpg"); */
    background: url("../../public/plus20x20.png");
}

.pmbutton.minus {
    /* background: url("../../public/minus_24x25.jpg"); */
    background: url("../../public/minus20x20.png");
}

.pmbutton:hover
{
    /* Scaling button to 0.98 to its original size */
    transform: scale(0.98);
    /* Lowering the shadow */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    cursor: initial;
}

.pmbutton:onmouseover
{
    cursor: initial;
}

.pmbutton:active
{
    /* Scaling button to 0.98 to its original size */
    transform: scale(0.98);
    /* Lowering the shadow */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.pmbutton.pbdisabled:active { box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); }
.pmbutton.pbdisabled { cursor: default; background: #c8c8c8; }


